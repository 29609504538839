<template>
  <ClientOnly>
    <div class="text-center">
      <v-menu v-model="menu" close-on-content-click location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn icon color="primary" v-bind="props">
            <v-avatar
              size="36"
              color="grey"
              class="white--text"
              :image="$auth.user.avatarUrl || '/assets/images/avatar.png'"
            />
          </v-btn>
        </template>
        <v-card min-width="300">
          <v-list color="primary">
            <v-list-item
              :prepend-avatar="
                $auth.user.avatarUrl || '/assets/images/avatar.png'
              "
              :subtitle="
                $auth.isSuperAdmin
                  ? `Superadmin`
                  : `Relawan (${$auth.user.verifiedStatus === 'NON_VERIFIED' ? 'Belum' : 'Sudah'} terverifikasi)`
              "
              :title="$auth.user.name"
            />
          </v-list>
          <v-divider></v-divider>
          <template v-if="$auth.isSuperAdmin">
            <v-list color="primary">
              <v-list-item
                to="/dashboard/admin/users"
                prepend-icon="mdi-account"
              >
                <v-list-item-title> Kelola Pengguna </v-list-item-title>
              </v-list-item>
              <v-list-item
                to="/dashboard/admin/activities"
                prepend-icon="mdi-calendar-edit"
              >
                <v-list-item-title> Kelola aktivitas </v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
          <template v-else>
            <v-list color="primary">
              <v-list-item to="/dashboard/profile" prepend-icon="mdi-account">
                <v-list-item-title> Profil </v-list-item-title>
              </v-list-item>
              <v-list-item
                to="/dashboard/my-activities"
                prepend-icon="mdi-calendar-check"
              >
                <v-list-item-title> Aktivitas saya </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="$auth.isVerified"
                to="/dashboard/activities"
                prepend-icon="mdi-calendar-edit"
              >
                <v-list-item-title> Kelola aktivitas </v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
          <v-divider></v-divider>
          <v-list color="primary">
            <v-list-item prepend-icon="mdi-logout" @click="$emit('logout')">
              <v-list-item-title> Keluar </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>

    <!-- Fallback during SSR -->
    <template #fallback>
      <div class="text-center">
        <v-btn icon color="primary">
          <v-avatar
            size="36"
            color="grey"
            class="white--text"
            image="/assets/images/avatar.png"
          />
        </v-btn>
      </div>
    </template>
  </ClientOnly>
</template>

<script setup lang="ts">
const { $auth } = useNuxtApp();

const emit = defineEmits(["logout"]);

const menu = ref(false);
</script>
